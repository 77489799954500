<template>

<app-page :locked="is.saving || is.deleting || is.copying">

	<app-page-head :title="title" :back="$route.meta.back">

		<app-page-head-action :disabled="!isDirty" icon="undo" tooltip="Undo changes" v-on:click.native="onUndoClick" />
		<app-page-head-action :disabled="!isDirty" :loading="is.saving" icon="save" tooltip="Save changes" v-on:click.native="onSaveClick" />
		<app-page-head-action v-if="!isNew" :loading="is.deleting" :danger="true" icon="delete" tooltip="Delete" v-on:click="onDeleteClick" />
		
	</app-page-head>

	<app-page-content :is-grown="true">

		<app-page-content-section :is-padded="true">

			<app-input-text :validation="$v.model.term" v-model="model.term" label="Term" placeholder="Enter term" />

		</app-page-content-section>

	</app-page-content>

	<app-page-foot />

</app-page>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			model: {
				id: 0,
				term: '',
				date: 0
			},
			noun: 'Request',
			param: 'request',
			store: 'requests'
		}

	},

	validations: {
		model: {
			term: {
				required
			}
		}
	}

}

</script>

<style scoped>

</style>
